import React from 'react';
import { Link } from 'gatsby';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
const Bg = () => {
  return(
  <StaticQuery
    query=
      {graphql`
        query {
          bg: file(relativePath: { eq: "customer-service-bg.png" }) {
            childImageSharp {
              fixed(width: 150) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
    render={data => (
      <Img alt="contacts" fixed={data.bg.childImageSharp.fixed}/>
    )}
  />
)};



export default Bg;