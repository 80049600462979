import React from 'react';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';
import { reset, typography, breakpoints, color } from '../../utils/style';
import Header from '../Header/HeaderNew';
import Breadcrumbs from './Breadcrumbs';

const Content = styled('div')`
  display: flex;
  min-height: 100vh;
  flex-direction: column; 
  background-position: bottom right;
  .top-nav {
    display: none;
  }
  @media (max-width: ${breakpoints.md}) {
    background: ${color.black};
    .top-nav {
      display: block;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    position: relative;
  }
  
`;

const Main = styled('main')`
  
`;

export default ({ children, breadcrumbs, page_title }) => {
  return([
  <Global key="reset" styles={css`${reset} ${typography}`}/>,
  <Content key="app-content" id="all-content">
    <Header key="app-header" location="/"/>
    <Main key="app-content-main">
      {page_title ? <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title}/> : ''}
      {children}
    </Main>
  </Content>
])}