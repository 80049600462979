import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/shared/layout-login-iframe';
import SEO from '../../components/SEO/SEO';
import styled from '@emotion/styled';
import Bg from '../../components/Content/Login/Bg';
import { Helmet } from 'react-helmet';

const IframeContainer = styled('div')`
  .c-wrapper {
    position: relative;
    width: 980px;
    margin: 0 auto;
  }
  @media (max-width: 1000px) {
    position: static;
    .c-wrapper {
      display: none;
    }
  }
`;

const ContactsContainer = styled('div')`
  position: absolute;
  top: 165px;
  right: -155px;
  height: 420px;
  width: 152px;

  ul {
    position: absolute;
    top: 10px;
    list-style: none;
    margin: 67px 0;
    padding: 0;
    text-align: center;
    width: 100%;
    div {
      color: #f0c62e;
      margin-bottom: 9px;
      font-size: 13px;
    }
  }
  a {
    display: block;
    margin-top: 10px;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;

const PageTemplate = ({ data }) => {
  const currentPage = data.wordpressPage;
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : currentPage.title + '- ' + process.env.OWNER;
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : '';
  const socialImage = currentPage.featured_media?.localFile?.publicURL;

  return (
    <Layout>
      <Helmet>
        <script type="text/javascript">
          {`
              window.$zopim || (function (d, s) {
		var z = $zopim = function (c) {
			z._.push(c)
		}, $ = z.s =
				d.createElement(s), e = d.getElementsByTagName(s)[0];
		z.set = function (o) {
			z.set.
					_.push(o)
		};
		z._ = [];
		z.set._ = [];
		$.async = !0;
		$.setAttribute('charset', 'utf-8');
		$.src = '//v2.zopim.com/?1lvcLl1DBZxz3cJMCOIoCMZvoikNP1b1';
		z.t = +new Date;
		$.
				type = 'text/javascript';
		e.parentNode.insertBefore($, e)
	})(document, 'script');
            `}
        </script>
      </Helmet>

      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        socialImage={socialImage}
      />
      <IframeContainer>
        <div className="c-wrapper">
          <ContactsContainer>
            <Bg />
            <ul>
              <li>
                <div>เบอร์ติดต่อศูนย์บริการ</div>
                <a href="tel:02-026-0697">TH 02-026-0697</a>
                <a href="tel:02-508-8798" className="last-phone">
                  TH 02-508-8798
                </a>
                <a
                  href="https://line.me/R/ti/p/%40lnwasia"
                  className="last-phone"
                >
                  Line: {process.env.LINE_ID_TEXT}
                </a>
              </li>
            </ul>
          </ContactsContainer>
        </div>
        <iframe
          id="iframeScrollbar"
          src="https://bbbs.bacc1688.com/"
          frameborder="0"
          width="100%"
          height="1000px"
        />
      </IframeContainer>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query($id: String!, $related: [Int], $contentImages: [String]) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
      }
      featured_media {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allWordpressPost(filter: { wordpress_id: { in: $related } }, limit: 4) {
      edges {
        node {
          slug
          title
          excerpt
          wordpress_id
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allWordpressWpMedia(filter: { source_url: { in: $contentImages } }) {
      edges {
        node {
          source_url
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1400) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
              }
            }
          }
        }
      }
    }
  }
`;
